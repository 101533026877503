import PropTypes from 'prop-types'
import classNames from 'classnames'
import styled from 'styled-components'
import NoSSR from 'react-no-ssr'
import { Container, Row, Col } from '@mch-group/uikit-components'
import Text from '@components/Text'
import { px2rem, hiDPIpx } from '@utils'
import mq from '@utils/styled-mq'
import cssClasses from '@constants/cssClasses'

const RmText = ({ ...props }) => (
  <section className={classNames(props.className, props.tm_gray_bg === 'true' ? 'is-gray' : '')}>
    <Container
      className={classNames(props.className, 'rm-text px-5 px-lg-8 px-md-7 ', 'main-text', cssClasses.CMS_MODULE)}
    >
      <Row>
        <Col className='col-lg-8 col-sm-12'>
          <NoSSR>
            <Text forwardedAs='p' isHtml className='text-small'>{props.text_module_text}</Text>
          </NoSSR>
        </Col>
      </Row>
    </Container>
  </section>
)

RmText.propTypes = {
  text_module_text: PropTypes.string,
  tm_gray_bg: PropTypes.string,
  className: PropTypes.string
}

const StyledRmText = styled(RmText)`
  &.is-gray {
    background: var(--bs-mch-gray-100);
    ${mq.between('tablet', 'largestTablet')`
      padding: ${hiDPIpx(30)} ${hiDPIpx(45)};
    `}
    .row {
      padding: ${px2rem(15)} 0 !important;

      &__column {
        ${mq.between('tablet', 'largestTablet')`
          padding: ${hiDPIpx(15)};
        `}
      }
    }
  }
`

export default StyledRmText
